.productFront{
    position: relative;
}
.productBackground{
    background: #fff;
    position: absolute;
    top:100px;
    bottom: 0;
    left:0;
    right:0;
    z-index: -1;
    transform: scale(.2, .9);
    transition:transform 250ms ease-in-out, opacity 100ms linear;
    opacity: 0;
    border-radius: 5px;
}
.homeProductItem:hover{

    .productBackground{
        transform: scale(1.2, 1.5);
        opacity: 1;
        border:2px solid #fa4248;
    }
    .productFront{
        transform: translateY(-15%);
    }
    .productImage{
        border:1px solid rgb(249, 169, 169);
        z-index: 10;
    }

    .catProductBtn{
        transform:  scale(1);
        opacity: 1;
    }

}
/* CSS Document */
@media only screen and (min-device-width : 320px) and (max-device-width : 768px)  { 
    .homeProductItem:hover{
        .productBackground{
            transform: none;
        }
        .productFront{
            transform: none;
        }
        .productImage{
            transform: none;
        }
    
        .catProductBtn{
            transform: none;
            display: none;
        }
    }
    .favContentWrap .catProductBtn{
        transform: none;
        display: block !important;
    }
}